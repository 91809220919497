import React from 'react';
import {Box, AnimatedBox} from '../box/index';
import {useNFTGhostItemAnimation} from './useNFTGhostItemAnimation';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NFTGhostItemProps {}

export const NFTGhostItem: React.FC = () => {
  const {shimmerStyles} = useNFTGhostItemAnimation();

  return (
    <Box
      width="100%"
      flexDirection="column"
      overflow="hidden"
      backgroundColor="surfaceDark"
    >
      <AnimatedBox style={{paddingTop: '100%', ...shimmerStyles}} />

      <Box width="100%" bg="surfaceDark" height={74} />
    </Box>
  );
};
