export const transformNumberOfColumnsToGridTemplateColumns = (
  numberOfColumnsResponsiveValue: number | number[],
  columnAlignmentResponsiveValue:
    | 'left'
    | 'center'
    | ('left' | 'center' | null)[],
): string | string[] => {
  const makeArrayOfGridTemplateColumnsCSSValues = (
    numberOfColumns: number,
    responsiveIndex: number,
  ) =>
    numberOfColumns
      ? new Array(numberOfColumns)
          .fill(null)
          .map(() =>
            // eslint-disable-next-line no-nested-ternary
            Array.isArray(columnAlignmentResponsiveValue)
              ? columnAlignmentResponsiveValue[responsiveIndex] === 'center'
                ? 'auto'
                : '1fr'
              : columnAlignmentResponsiveValue === 'center'
              ? 'auto'
              : '1fr',
          )
          .join(' ')
      : '1fr';

  if (!Array.isArray(numberOfColumnsResponsiveValue))
    return makeArrayOfGridTemplateColumnsCSSValues(
      numberOfColumnsResponsiveValue,
      0,
    );

  return numberOfColumnsResponsiveValue.map(
    makeArrayOfGridTemplateColumnsCSSValues,
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const transformGridTemplateColumnsToJustifyContentValue = (
  value: string | string[],
) => {
  const gridTemplateColumnValueToJustifyContentValue = (
    gridTemplateColumnValue: string,
  ) => (gridTemplateColumnValue.startsWith('auto') ? 'center' : 'flex-start');

  if (!Array.isArray(value))
    return gridTemplateColumnValueToJustifyContentValue(value);

  return value.map(gridTemplateColumnValueToJustifyContentValue);
};
