import {useToastContext} from '../contexts/toast-context';
import {useTtxMutation} from './ttx-trpc';

export const useSubscribe: () => {
  onSubscribeSubmit: (values: {
    email: string;
    subscribeTemplate?: number;
    subscribeList?: number;
    callBack: (err: boolean) => void;
  }) => void;
} = () => {
  const toast = useToastContext();

  const {...subscribe} = useTtxMutation('data-capture.subscribe');

  // eslint-disable-next-line consistent-return
  const onSubscribeSubmit = async (values: {
    email: string;
    subscribeList?: number;
    subscribeTemplate?: number;
    callBack: (err: boolean) => void;
  }) => {
    subscribe.mutate(
      {
        email: values.email,
        subscribeTemplate: values.subscribeTemplate,
        subscribeList: values.subscribeList,
      },
      {
        onSuccess: async () => {
          toast.addSuccessToast(`You’ve successfully subscribed.`);
          values.callBack(true);
        },
        onError: async () => {
          toast.addErrorToast('Error Try again later.');
          values.callBack(false);
        },
      },
    );
  };

  return {onSubscribeSubmit};
};
