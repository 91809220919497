import {Box} from '@ttx/design-system';
import React from 'react';

interface HomePageConatinerProps {
  isMobile: boolean;
  topZero?: boolean;
  children: React.ReactElement;
}

export const HomePageConatiner: React.FC<any> = ({
  isMobile,
  topZero,
  children,
}: HomePageConatinerProps) => {
  return (
    <Box
      margin={['four', 'eight', 'twelve']}
      style={topZero ? {marginTop: '0px'} : {}}
    >
      {children}
    </Box>
  );
};
