import {useSpring} from '../animation';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useNFTGhostItemAnimation = () => {
  const shimmerBase = 'rgba(207, 210, 195, 0.5)';
  const shimmerColor = 'rgba(207, 210, 195, 0.2574)';

  const shimmerStyles = useSpring({
    loop: true,
    to: [
      {
        background: `linear-gradient(141.69deg, ${shimmerBase} -10%, ${shimmerBase} -50%, ${shimmerBase} 145%)`,
      },
      {
        background: `linear-gradient(141.69deg, ${shimmerBase} 0.83%, ${shimmerColor} 21.67%, ${shimmerBase} 145%)`,
      },
      {
        background: `linear-gradient(141.69deg, ${shimmerBase} 1.25%, ${shimmerColor} 120.46%, ${shimmerBase} 145%)`,
      },
      {
        background: `background: linear-gradient(141.69deg, ${shimmerBase} 10%, ${shimmerBase} 200%, ${shimmerBase} 145%)`,
      },
    ],
    from: {background: shimmerBase},
    config: {duration: 400},
  });

  return {
    shimmerStyles,
  };
};
