import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface TickedBoxIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const TickedBoxIcon = React.memo<TickedBoxIconProps>(
  ({color = 'outlines', size = 18}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.408 7.71L8.9 11.77L14.108 4.686L13.156 4L9.012 9.796L7.374 7.066L6.408 7.71ZM5.19 12.918V5.26H10.342V4.196H4V13.996H13.8V9.404H12.596V12.918H5.19Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
