import React, {useMemo} from 'react';
import {CSSProperties} from '@stitches/react';
import {
  objectKeys,
  transformStyleProps,
} from '../theme/stitches/variant-utilities';
import {styledBox} from '../box';
import {
  transformGridTemplateColumnsToJustifyContentValue,
  transformNumberOfColumnsToGridTemplateColumns,
} from './utils';
import {gridProperties} from './grid-properties';
import {GridBoxProps} from './types';
import {theme} from '../theme/theme';

const variants = {
  ...gridProperties,
};

const baseStyle: CSSProperties = {
  display: 'grid',
  gridAutoFlow: 'row',
  width: '100%',
  border: '1px solid white',
  backgroundColor: 'white',
};

const StyledBox = styledBox({
  ...baseStyle,
  variants,
});

const variantPropNames = [...objectKeys(gridProperties)];

export const GridBox = React.memo(
  React.forwardRef<HTMLDivElement, GridBoxProps>(
    (
      {columns, space, rowSpace, columnSpace, alignColumns = 'left', ...rest},
      ref,
    ) => {
      const transformedSpaceProps = transformStyleProps(
        variantPropNames,
        {
          space,
          rowSpace,
          columnSpace,
        },
        [...theme.breakpoints],
      );

      const transformedColumnsPropValue = useMemo(
        () =>
          transformNumberOfColumnsToGridTemplateColumns(columns, alignColumns),
        [columns, alignColumns],
      );

      const transformedJustifyColumnPropValue = useMemo(
        () =>
          transformGridTemplateColumnsToJustifyContentValue(
            transformedColumnsPropValue,
          ),
        [transformedColumnsPropValue],
      );

      return (
        <StyledBox
          {...(rest as any)}
          {...transformedSpaceProps}
          columns={transformedColumnsPropValue}
          justifyContent={transformedJustifyColumnPropValue}
          ref={ref as any}
        />
      );
    },
  ),
);
