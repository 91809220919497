import {theme} from '../theme/theme';
import {
  propertyShorthandToVariant,
  VariantProps,
} from '../theme/stitches/variant-utilities';

export const gridProperties = {
  ...propertyShorthandToVariant(
    'space',
    ['gridRowGap', 'gridColumnGap'],
    theme.space,
  ),
  ...propertyShorthandToVariant('rowSpace', ['gridRowGap'], theme.space),
  ...propertyShorthandToVariant('columnSpace', ['gridColumnGap'], theme.space),

  ...propertyShorthandToVariant('alignColumns', ['justifyContent'], {
    left: 'left',
    center: 'center',
  }),
};

export type GridPropertiesProps = VariantProps<typeof gridProperties>;
