import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface UntickedBoxIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const UntickedBoxIcon = React.memo<UntickedBoxIconProps>(
  ({color = 'outlines', size = 18}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.814 13.8V4H4V13.8H13.814ZM5.204 5.064H12.61V12.722H5.204V5.064Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
