import React from 'react';
import {Pressable} from 'react-native';
import {Box} from '../box/index';
import {Typography} from '../typography';
import {TickedBoxIcon, UntickedBoxIcon} from '../icons';

interface CheckboxProps {
  label: string;
  checked: boolean;
  onCheckboxPress: () => void;
  error?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  onCheckboxPress,
  error,
}: CheckboxProps) => {
  return (
    <Box flex={1} maxWidth="100%">
      <Pressable onPress={onCheckboxPress}>
        <Box flexDirection="row" alignItems="center" flex={1}>
          {checked ? (
            <Box>
              <TickedBoxIcon size={20} />
            </Box>
          ) : (
            <Box>
              <UntickedBoxIcon size={20} />
            </Box>
          )}
          <Box ml="s" flex={1}>
            <Typography textStyle="s" color="textDefault">
              {label}
            </Typography>
          </Box>
        </Box>
      </Pressable>
      <Box pt="s">
        <Typography textStyle="s" color="errorPrimary">
          {error}
        </Typography>
      </Box>
    </Box>
  );
};
